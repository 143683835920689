.filterInput {
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-size: 13px !important;
    font-weight: 300;
    font-family: Poppins, Helvetica, sans-serif;
    width: 49%;
    height: 38px;
    vertical-align: baseline;
}

.filterRow{
    background-color: #BCDFEF;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
}