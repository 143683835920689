.verticalLine {
    background-color: #000;
    height: 100vh;
    margin: 0 auto;
    width: 3px;
}
.inputFile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputLabel {
    height: 30px;
    border-radius: 10px;
    border-style: none;
    box-shadow: 0 0 3px gray;
    color: #4f555b;

    font-weight: normal;
    font-size: 15px;
    cursor: pointer;
}
.adminFile::-webkit-file-upload-button {
    color: white;
    display: inline-block;
    background: #1CB6E0;
    border: none;
    padding: 7px 15px;
    font-weight: 700;
    border-radius: 3px;
    white-space: nowrap;
    cursor: pointer;
    font-size: 10pt;
}
