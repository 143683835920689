header {
  margin: 10px;
}
#maxWidth {
  max-width: 600px;
}
.kt-portlet{
  /*width: 1650px !important;*/
}
#marginRight {
    margin-right: 4px;
}
.pagination {
  display:block;
  list-style:none;
  margin:0;
  padding:0;
}
.pagination li {
  display:inline-block;
}
.pagination li a {
  display:block;
  margin:0px 2px 10px 0px;
  padding:8px 14px;
  border-radius:3px;
}
.pagination li a:hover{
  text-decoration:none;
  cursor:pointer;
  background:#007bff;
  box-shadow:rgba(0,0,0,0.1) 0px 5px 25px;
}
.pagination li a:focus{
  outline:none;
}
.pagination li.active a {
  color:#fff;
  background:#ED254E;
}
.pagination li.disabled a {
  color:#fff;
  background:#555;
  opacity:0.25;
  cursor:default;
}
