.portalTitle{
    font-size: 18px;
    /*color: ;*/
}

.img-responsive {
    max-width: 100%;
}

.redText{
    color: #08439d;
}

.registrationTextDiv{
    width: 100%;
    background-color: rgba(245, 245, 245, 0.38);
    padding: 20px;
    border-radius: 10px;
}

.registrationText{
    color:black;
    font-size: 14px;
    font-family: Poppins, Helvetica, sans-serif;
    text-align: justify;
}

.faqLink {
    border: 1px solid #08439d;
    border-radius: 10px;
    padding: 5px;
    color: #08439d;
}

.faqLink:hover {
    background-color: white;
    color: #08439d;
    text-decoration: none;
}

.btnLogin:hover {
    background: #08439d;
    color: white;
}

.btnLogin{
    border-radius: 10px !important;
}

.btnDirector{
    width: 30%;
    border-radius: 10px !important;
    margin: 0rem 1rem 1rem 1rem;
}

/*Buttons in Forgot Password Page*/

.btnSend{
    border-radius: 10px !important;
}

.btnClose{
    border-radius: 10px !important;
}

/*LOGIN INPUTS STYLE*/

. {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 280px;
    border-radius: 3px;
    overflow: hidden;
}
.inp .label {
    position: absolute;
    top: 20px;
    left: 12px;
    font-size: 16px;
    color: rgb(222, 236, 252);
    font-weight: 500;
    transform-origin: 0 0;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
    pointer-events: none;
}
.inp .focus-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(222, 236, 252);
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
}
.inp input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 185%;
    border: 0;
    font-family: inherit;
    padding: 16px 12px 0 12px;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    background: rgb(241, 250, 255);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
    color: #000;
    transition: all 0.15s ease;
    border-radius: 10px;
}
.inp input:hover {
    background: rgba(0, 0, 0, 0.04);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
}
.inp input:not(:-moz-placeholder-shown) + .label {
    color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:not(:-ms-input-placeholder) + .label {
    color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:not(:placeholder-shown) + .label {
    color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:focus {
    background: rgba(0, 0, 0, 0.05);
    outline: none;
    box-shadow: inset 0 -2px 0 #0077FF;
}
.inp input:focus + .label {
    color: #0077FF;
    transform: translate3d(0, -12px, 0) scale(0.75);
}
.inp input:focus + .label + .focus-bg {
    transform: scaleX(1);
    transition: all 0.1s ease;
}