.close-modal {
    width: 500px !important;
    height: 210px !important;
}

.smallEditModal {
    width: 500px !important;
    height: 290px !important;
}

.crudSaveButton {
    background: rgba(0, 229, 0, 0.93) !important;
    color: white !important;
}

.pendingButton {
    background-color: #67c4ef !important;
    margin-top: 15px;
    border-radius: 15px !important;
}