.napDiv {
    width: 100%;
    height: 200px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.center {
    text-align: center;
    margin-top: 5px;
}

#addBtn {
    margin: 10px;
    background: #03DAC5 !important;
    border-radius: 20px !important;
}

#addBtn span {
    color: black;
    padding: 5px;
    margin-left: 5px;
}

.addNapBtn {
    color: black !important;
    margin: 10px;
    background: #03DAC5 !important;
    border-radius: 20px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
}

.addNapBtn span {
    color: black;
    margin-left: 5px;
}

#moreLink {
    color: #046cee;
    margin: 0;
    border: none !important;
    padding: 7px;
}

#moreLink:hover {
    border: none !important;
    color: white;
}