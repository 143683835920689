.generateBtn {
    border-radius: 15px !important;
}

.dropdown-content{
    background: white;
}

.dropdown-heading-value{
    background: white;
    padding: 0.8rem 1rem;
    border: 1px solid #dee2e6;
}

