td {
    word-wrap: break-word;
}

.searchInstitutionsInputStyle {
    border-radius: 20px;
    border-style: none;
    box-shadow: 0 0 4px #1C4857;
}

.InstitutionAdminActionButton {
    float: left;
    margin-left: 5px;
    width: 110px;
    height: 35px;
    opacity: 1 !important;
}

.defaultBtnPreview {
    background-color: #0a6e0a;
    color: white !important;
    border: 1px solid black;
    border-radius: 20px !important;
}
