.list-group-item.active{
    background-color: steelblue;
}

ul.institutionsList {
    list-style: none;
}

ul.institutionsList li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #EABF32; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1.2em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}


.float-left-animation{
    animation-duration: 1s;
    animation-name: slidein;
}


@keyframes slidein {
    from {
        margin-left: 100%;
        width: 100%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}
