.myFeedbackPublicationsContainer {
    background-color: white !important;
    border-radius: 20px;
    height: 100px;
    box-shadow: 0 0 3px gray;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.myFeedbackPublicationsRowFooter {
    height: 15px;
    width: 99% !important;
    border-top: 1px solid dimgrey;
    border-radius: 10px;
    display: flex;

}

.myFeedbackPublicationsCol {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.myFeedbackPublicationsPublicationTitleCol {
    display: flex;
    align-items: center;
    color: #1C4857;
}

.myFeedbackPublicationsButton {
    border-radius: 10px !important;
    float: right;
}

.myFeedbackPublicationsColFooter {
    display: flex;
}

.myFeedbackPublicationsFooterText {
    color: #343A40 !important;
    font-size: 13px;
}

.myFeedbackPublicationsFooterRightText {
    margin-left: auto;
    order: 2;
}

