.designFeedbackItem {
display: inline-block;
vertical-align: top;
height: 100%;
font-weight: 400;
font-size: 13px;
font-family: Poppins, Helvetica, sans-serif;
word-break: break-all;
background-color: #ffffff;
border-color: #f2f3f8;
border-style: solid;
border-radius: 3px;
}

.headerCell {
    margin-right: 0.25rem;
    display: inline-block;
    font-size: 13.5px;
    font-weight: bold;
    color: darkblue;
}

.headerColumn {
    border: solid;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color:  #BCDFEF !important;
    border-color: #BCDFEF;
}