.userRejectionButton{
    background-color: transparent !important;
    color: darkred !important;
    border: 1px solid darkred !important;
    border-radius: 10px !important;
    padding: 6px !important;
}

.userRejectionButton:hover{
    background-color: transparent !important;
    color: darkred !important;
    border: 2px solid darkred !important;
    border-radius: 10px !important;
    padding: 6px !important;
    font-weight: bold !important;
}
