.activity {
    border-radius: 20px;
    /*other background - #DFF6FF*/
    background: white;
    border: 3px solid grey;
    margin-bottom: 15px;
    box-shadow: 2px 2px 2px grey;
    letter-spacing: 1px;
}

.activityLeft {
    border-right: 1px solid grey;
}