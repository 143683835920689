.pro-sidebar-layout  {
    background: #BCDFEF;
    color: black;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: darkblue;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    font-weight: bold;
    color: darkblue;
}

.pro-sidebar .pro-menu a:hover {
    color: darkblue;
}

.pro-sidebar .pro-menu a:focus {
    font-weight: bold;
    color: darkblue;
}

.pro-sidebar-layout {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

.pro-sidebar-layout::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: #67C4EF;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
    border-radius: 0 20px 20px 0;
}

.pro-sidebar > .pro-sidebar-inner {
    border-radius: 0 22px 22px 0;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    background: #BCDFEF;
}

.pro-sidebar .pro-menu a {
    color: black;
}

.sidebar-btn-wrapper .sidebar-btn {
    padding: 1px 35px;
    border-radius: 40px;
    background: hsla(0,0%,100%,.05);
    color: #adadad;
    text-decoration: none;
    margin: 0 auto;
    height: 35px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sidebar-btn-wrapper{
    padding: 10px 20px 24px 20px;
}

.sidebar-btn-wrapper .sidebar-btn:hover{
    color: #d1d7e2;
}
.sidebar-btn-wrapper, .sidebar-btn-wrapper .sidebar-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pro-item-content {
    white-space: pre-wrap !important;
}